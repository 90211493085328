import 'keen-slider/keen-slider.min.css'

import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Cursor } from 'app/components/Common/Cursor'
import { Dot } from 'app/components/Common/Dot'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Spinner } from 'app/components/Common/Spinner'
import { useKeenSlider } from 'keen-slider/react'
import React, { memo, useEffect, useRef, useState } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  images: ImageProps[]
  pretitle?: string
  title?: string
}

export const GalleryRooms = memo(function GalleryRooms({
  cta,
  description,
  images,
  pretitle,
  title,
}: Props) {
  if (!images) {
    return null
  }
  if (images.length < 1) {
    return null
  }

  const containerRef = useRef<any>()
  const [currentSlide, setCurrentSlide] = useState(0)
  const [details, setDetails] = useState<any>(null)
  const [loaded, setLoaded] = useState<boolean[]>([])
  const [nextLoaded, setNextLoaded] = useState<boolean[]>([])

  function positionStyle(idx: any) {
    if (!details) {
      return undefined
    }

    const position = details.slides[idx].distance
    const x = (galleryRef.current?.size || window.innerWidth) * -position
    return {
      transform: `translate3d(${x}px, 0px, 0px)`,
      WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
    }
  }

  const [sliderRef, galleryRef] = useKeenSlider({
    defaultAnimation: {
      duration: 1200,
    },
    drag: false,
    loop: images.length > 1 ? true : false,
    slideChanged(s) {
      setCurrentSlide(s.track.details.rel)
    },
    detailsChanged(s) {
      images.length > 1 ? setDetails(s.track.details) : null
    },
    breakpoints: {
      '(max-width: 991px)': {
        drag: true,
      },
    },
  })

  useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[currentSlide] = true
    const newNextLoaded = [...nextLoaded]
    newNextLoaded[currentSlide + 1] = true

    setLoaded(newLoaded)
    setNextLoaded(newNextLoaded)

    setLoaded(newLoaded)
  }, [currentSlide, galleryRef, sliderRef])

  return (
    <Container>
      <Wrapper>
        {pretitle ? (
          <FadeInUp>
            <Line />
            <Pretitle>{pretitle}</Pretitle>
          </FadeInUp>
        ) : null}
        {title ? <Title text={title} /> : null}
        {description ? (
          <FadeInUp>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
          </FadeInUp>
        ) : null}
        {cta ? (
          <FadeInUp>
            <StyledButton {...cta} />
          </FadeInUp>
        ) : null}
      </Wrapper>

      <CarouselContainer ref={containerRef}>
        <FadeInUp>
          <Overlay />
          <Carousel ref={sliderRef} className="keen-slider">
            {images.map((image, index) => (
              <Slide key={index} className="keen-slider__slide">
                <Inner style={positionStyle(index)}>
                  <Image
                    {...(loaded[index] || nextLoaded[index] ? image : null)}
                  />
                  <Spinner />
                </Inner>
              </Slide>
            ))}
          </Carousel>
        </FadeInUp>

        {images.length > 1 ? (
          <Cursor container={containerRef} gallery={galleryRef} />
        ) : null}

        {images.length > 1 ? (
          <Dots>
            {images.map((item, index) => (
              <Dot
                key={index}
                className={currentSlide === index ? 'active' : undefined}
                onClick={() => {
                  galleryRef.current?.moveToIdx(index)
                }}
              />
            ))}
          </Dots>
        ) : null}
      </CarouselContainer>
    </Container>
  )
})

const Container = styled.section`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
`

const Wrapper = styled.div`
  position: relative;
  width: 40vw;
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: -13vw;
  text-align: center;
  z-index: 4;

  @media (max-width: 991px) {
    margin-bottom: 30px;
    width: 70vw;
  }
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  position: relative;
  margin-top: 4.16vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.13;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 20px;
  }
`

const Description = styled.div`
  position: relative;
  margin-top: 1.19vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.38vw;

  @media (max-width: 1439px) {
    font-size: 15px;
    margin-top: 10px;
    line-height: 26px;
  }

  @media (max-width: 991px) {
    margin-top: 20px;
    br {
      display: none;
    }
  }
`

const StyledButton = styled(Button)`
  margin: 3.33vw auto 0;

  @media (max-width: 991px) {
    margin: 20px auto;
  }
`

const CarouselContainer = styled.div`
  position: relative;
  overflow: hidden;

  @media (min-width: 992px) {
    &:hover {
      cursor: none;
    }
  }
`

const Overlay = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  pointer-events: none;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0.7) 100%
  );
`

const Carousel = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 48.61vw;
  overflow: hidden;

  @media (max-width: 991px) {
    height: 70vw;
  }
`

const Slide = styled.div`
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Dots = styled.div`
  position: absolute;
  top: 50%;
  left: 3.33vw;
  transform: translateY(-50%);
  z-index: 3;
`
