import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Dots } from 'app/components/Common/Dots'
import React, { memo } from 'react'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'

export interface Props {
  title?: string
}

export const BigTitle = memo(function BigTitle({ title }: Props) {
  return (
    <Container>
      <StyledDots width={11} height={7} variant="red" />
      <ParallaxProvider>
        <Parallax className="slider-parallax" translateY={['-15%', '15%']}>
          <TitleWrapper>
            <StyledFadeInUp delay={0.1}>
              <img src="/libellula.svg" alt="libellula" />
            </StyledFadeInUp>
            <StyledFadeInUp delay={0.15}>
              <img src="/water.svg" alt="water" />
            </StyledFadeInUp>
            <StyledFadeInUp delay={0.2}>
              <img src="/flower.svg" alt="flower" />
            </StyledFadeInUp>
            {title ? <Title text={title} /> : null}
          </TitleWrapper>
        </Parallax>
      </ParallaxProvider>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  padding: 10vw 10vw;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-bottom: -1px;

  @media (max-width: 991px) {
    padding: 70px 30px;
  }
`
const StyledFadeInUp = styled(FadeInUp)`
  display: inline-block;
`
const StyledDots = styled(Dots)`
  position: absolute;
  right: 10vw;
  top: 10vw;
`

const TitleWrapper = styled.div`
  position: relative;

  img {
    height: 2.5vw;
    width: auto;
    margin-right: 2.5vw;
  }

  @media (max-width: 991px) {
    img {
      height: 20px;
      margin-right: 15px;
    }
  }
`

const Title = styled(AnimatedTitle)`
  margin-top: 4.16vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.13;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 20px;
  }
`
