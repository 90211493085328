import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { Line } from 'app/components/Common/Line'
import { ArrowsRight } from 'app/components/Icons'
import { useVocabularyData } from 'app/utils/vocabulary'
import { Link } from 'gatsby'
import React, { memo } from 'react'

export interface ItemProps {
  title?: string
  url: string
}

export interface Props {
  cta?: ButtonProps
  items: ItemProps[]
  languageCode: string
  pretitle?: string
  title?: string
}

export const DealsPreview = memo(function DealsPreview({
  cta,
  items,
  languageCode,
  pretitle,
  title,
}: Props) {
  if (!items) {
    return null
  }
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      <Heading>
        <Inner>
          {pretitle ? (
            <FadeInUp>
              <Line />
              <Pretitle>{pretitle}</Pretitle>
            </FadeInUp>
          ) : null}
          {title ? <Title text={title} /> : null}
        </Inner>
        {cta ? (
          <FadeInUp>
            <Button {...cta} variant="light" />
          </FadeInUp>
        ) : null}
      </Heading>

      <Items>
        {items.map((item, index) => (
          <FadeInUp delay={index * 0.1} key={index}>
            <Item key={index} to={item.url} className={`item--${index % 3}`}>
              {item.title ? <Label>{item.title}</Label> : null}
              <ItemButton>
                {useVocabularyData('find-out-more', languageCode)}
                <ArrowsRight />
              </ItemButton>
            </Item>
          </FadeInUp>
        ))}
      </Items>
    </Container>
  )
})

const Container = styled.section`
  padding: 25vw 10vw 10vw;
  background-color: ${({ theme }) => theme.colors.variants.neutralDark2};
  margin-bottom: -1px;

  @media (max-width: 991px) {
    padding: 70px 30px;
  }
`

const Heading = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  @media (max-width: 991px) {
    display: block;
  }
`

const Inner = styled.div``

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  margin-top: 2.08vw;
  color: ${({ theme }) => theme.colors.variants.neutralLight2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.15;

  @media (max-width: 991px) {
    font-size: 28px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

const Items = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 2.63vw;
  margin-left: -4.16vw;

  @media (max-width: 991px) {
    margin-top: 30px;
    display: block;
    margin-left: 0;
  }
`

const Label = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 1.66vw;
  line-height: 1.25;
  transition: color 0.25s ease-in-out;

  @media (max-width: 991px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`

const ItemButton = styled.div`
  display: inline-block;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.variants.neutralLight1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 600;
  font-size: 0.9vw;
  transition: color 0.25s ease-in-out;

  @media (max-width: 1439px) {
    font-size: 13px;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 1.31vw;
    margin-left: 0.83vw;
    fill: ${({ theme }) => theme.colors.variants.neutralLight1};
    transition: margin 0.25s ease-in-out, fill 0.25s ease-in-out;

    @media (max-width: 1439px) {
      margin-left: 12px;
      height: 19px;
    }
  }
`

const Item = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23.88vw;
  height: 22.22vw;
  margin-left: 4.16vw;
  padding: 3.33vw;
  border: 3px solid transparent;
  border-radius: 0.69vw;
  transition: background 0.25s ease-in-out, border 0.25s ease-in-out;

  &.item--0 {
    background: ${({ theme }) => theme.colors.variants.primaryLight};
  }
  &.item--1 {
    background: ${({ theme }) => theme.colors.variants.neutralLight3};

    ${Label} {
      color: ${({ theme }) => theme.colors.variants.neutralDark2};
    }

    ${ItemButton} {
      color: ${({ theme }) => theme.colors.variants.neutralDark2};

      svg {
        fill: ${({ theme }) => theme.colors.variants.neutralDark2};
      }
    }
  }
  &.item--2 {
    background: ${({ theme }) => theme.colors.variants.primaryDark};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    border: 3px solid ${({ theme }) => theme.colors.variants.neutralLight1};

    ${Label} {
      color: ${({ theme }) => theme.colors.variants.neutralLight1};
    }

    ${ItemButton} {
      color: ${({ theme }) => theme.colors.variants.neutralLight1};

      svg {
        margin-left: 1.25vw;
        fill: ${({ theme }) => theme.colors.variants.primaryDark};

        @media (max-width: 1439px) {
          margin-left: 18px;
        }
      }
    }
  }

  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 15px;
    border-radius: 6px;
    padding: 10px;
  }
`
